import React from 'react'
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import '../styles/footer.css'

const footer = () => {
  return (
    <div className='footer-main'>
        <ul className='footer-links footer-text'>
            <li className='footer-element'>@2023 CoolVPN all rights reserved</li>
            <li className='footer-element'>Privacy Policy</li>
            <li className='footer-element'>Terms of service</li>
        </ul>
        <ul className='footer-links'>
            <li className='footer-element'><FaTwitter icon={FaTwitter} /></li>
            <li className='footer-element'><FaFacebook  icon={FaFacebook} /></li>
            <li className='footer-element'><FaInstagram icon={FaInstagram} /></li>
        </ul>
    </div>
  )
}

export default footer