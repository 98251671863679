import React from 'react'
import '../styles/support.css'

const support = () => {
  return (
    <body className='support-body'>
        <div className='support-container'>
            <div className='support-card'>
                <h2 className='support-header'>Get Live 24-hour customer support</h2>
                <p className='support-text'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                    Dolores quos culpa exercitationem. Error pariatur eligendi provident voluptate deleniti! 
                    Quas nostrum voluptatem sed, 
                    temporibus fugit facere eos corrupti tempore qui tempora.
                </p>

                <button className='support-button'>Contact Support</button>
            </div>

            <div className='support-card'>
                <h2 className='support-header'>30 day money back guarantee</h2>
                <p className='support-text'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                    Dolores quos culpa exercitationem. Error pariatur eligendi provident voluptate deleniti! 
                    Quas nostrum voluptatem sed, 
                    temporibus fugit facere eos corrupti tempore qui tempora.
                </p>

                <button className='support-button support-button-2'>Get Cool VPN today</button>
            </div>

        </div>

    </body>
  )
}

export default support